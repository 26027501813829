import { Component, Vue } from "vue-property-decorator";

import { STHeader,STFooter } from "@/layout";
import { VideoModel } from "@/models";
import { StCommon } from "../Common";
import { ErrorCode } from "@/constant";

export class VideoForm {
    title = '';
    link = '';
    active = '';
    vid = '';
}

@Component<ApStudentVideosInfoController>({
    components: {
        STHeader,STFooter
    }
})

export default class ApStudentVideosInfoController extends Vue {
    private videoForm = new VideoForm();
    private token = window.localStorage.getItem('studentToken') as string;

    public async created() {
        const item = {
            vid: this.$route.query.vid,
            token: this.token,
        }
        const editData = await VideoModel.stGet(item);
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: unknown) {
        const key = data as {
            ERR_CODE: number,
            info: object,
        }
        if (key.ERR_CODE === ErrorCode.InvalidToken) {
            StCommon.logout();
            return;
        }
        const item = key.info as { [key: string]: string };

        this.videoForm.vid = item.vid;
        this.videoForm.title = item.title;
        this.videoForm.link = '<iframe width="560" height="315" src="https://www.youtube.com/embed/'+item.link+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        this.videoForm.active = item.active;
    }
}